<template>
  <div v-if="mainData.length !== 0" class="college-constitution">
    <div
      class="list"
      @click="openConstitutionInfo(item)"
      v-for="(item, index) in mainData"
      :key="index"
    >
      <div class="year">{{item.Year}}</div>
      <div class="title">{{item.Title}}</div>
    </div>
  </div>
  <div v-else class="have-no-data">暂无数据...</div>
</template>

<script>
  // import { SchoolConstitutionList } from '@/api/college_details'
  import API from '@/api/config'
  export default {
    name: "CollegeConstitution",
    data(){
      return{
        mainData: [],
        query:{
          SchoolId: this.$route.query.id,
          PageIndex: 1,
          PageSize: 999
        }
      }
    },
    mounted(){
      this.initialize()
    },
    methods:{
      //open info
      openConstitutionInfo(item){
        let routerUrl = this.$router.resolve({
          path: '/constitution-details',
          query:{
            Id: item.ID
          }
        })
        window.open(routerUrl.href)
      },
      //initialize data
      initialize(){
        this.getConstitutionList()
      },
      //get constitution list
      getConstitutionList(){
        API.School.SchoolConstitutionList(this.query).then(res => {
          this.mainData = res.data.Data
        })
      }
    }
  }
</script>

<style scoped lang="less">
  // @import '~@/styles/base.scss';
  .college-constitution{
    padding: 3rem 0 0;
    margin-bottom: 10rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .list{
      width: 100%;
      padding: 1rem;
      margin: 0 2px 2rem;
      text-align: center;
      display: flex;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 2px 4px 0 var(--boxShadow);
      color: var(--text-font-color);
      border-radius: 2px;
      .year{
        margin-right: 1rem;
        padding-right: .5rem;
        border-right: 1px solid var(--color-grey);
      }
    }
  }
  .have-no-data{
    height: 15rem;
    padding: 3rem 0;
    color: rgba(var(--themecolor),1);
    font-size: 20px;
    text-align: center;
  }

</style>
