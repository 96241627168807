<template>
  <div class="main">
    <div class="head">
      <!-- <div v-if="directionData.length !== 0" class="head-top">
        <el-radio-group
          v-model="query.EnrollmentDirectionId"
          @change="selectParameters"
        >
          <el-radio-button
            v-for="(item, index) in directionData"
            :key="index"
            :label="item.ID"
          >{{ item.DirectionName }}
          </el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="head-bottom">
        <div class="province screen-item">
          <div class="title">招生省份：</div>
          {{ provinceObj.ProvinceName }}
          <!-- <el-select v-model="query.ProvinceId" placeholder="请选择省份" @change="selectProvince">
            <el-option v-for="(item, index) in provinceList" :key="index" :label="item.ProvinceName" :value="item.ID" />
          </el-select> -->
        </div>
        <!--<div class="year screen-item">
          <div class="title">年份：</div>
          <el-select v-model="query.Year" placeholder="请选择年份" @change="selectParameters">
            <el-option
              v-for="(item,index) in YearData"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>-->
        <div>
          <template v-if="!isNewExam || showOldmes">
            <div class="subject screen-item">
              <div class="title">科类：</div>
              <el-radio-group v-model="query.SubjectType" @change="selectParameters">
                <el-radio-button :label="1">文科</el-radio-button>
                <el-radio-button :label="2">理科</el-radio-button>
              </el-radio-group>
              <span class="textBtn" v-if="showOldmes" @click="oldTextBtn">查看新高考数据</span>
            </div>
          </template>
          <template v-else-if="(isNewExam || !showOldmes) && newExamActive">
            <div class="new-exam screen-item">
              <div class="title">科目：</div>
              <el-radio-group v-model="query.SelectSubject" @change="selectParameters">
                <el-radio-button label="物理">物理</el-radio-button>
                <el-radio-button label="历史">历史</el-radio-button>
              </el-radio-group>
              <span class="textBtn" v-if="!showOldmes" @click="oldTextBtn">查看老高考数据</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-table">
        <el-table :data="mainData.Data" border empty-text="暂无数据..."
        :header-cell-style="{background: 'rgba(var(--themecolor),0.2)', color: '#555'}" style="width: 100%">
          <el-table-column align="center" prop="SchoolName" label="院校名称" />
          <el-table-column align="center" prop="Year" label="年份" />
          <!-- <el-table-column
            align="center"
            prop="ScoreHigh"
            width="160"
            label="最高分"
          /> -->
          <el-table-column align="center" prop="ScoreLow" width="160" label="最低分" />
          <el-table-column align="center" width="160" label="平均分">
            <template slot-scope="props">
              <div>{{ props.row.ScoreAvg }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="RankLow" width="160" label="最低位次" />
          <el-table-column align="center" prop="ScoreDiff" width="140" label="线差" />
          <el-table-column align="center" prop="EnrollPersons" label="录取人数" width="100" />
          <el-table-column align="center" prop="BatchName" width="140" label="批次" />
        </el-table>
      </div>
      <custom-pagination v-show="mainData.Total > 0" :pageIndex="query.PageIndex" :pageSize="query.PageSize"
        :total="mainData.Total" @handleCurrentChange="turnPage"></custom-pagination>
    </div>
  </div>
</template>

<script>
// import { WishYear } from "@/api/common";
// import { SchoolEnrollment, SchoolEnrollmentDirection } from "@/api/search_university";
import { getStore } from '@/utils/utils';
import API from '@/api/config'
import {areaData} from '@/utils/data';
export default {
  data() {
    return {
      schoolID: "",
      mainData: [],
      directionData: [],
      provinceList: [],
      //新高考状态参数
      isNewExam: false,
      newExamActive: false,
      showOldmes: false,
      //查询参数
      query: {
        SchoolId: "",
        // EnrollmentDirectionId: "",
        ProvinceId: "",
        SubjectType: 1,
        SelectSubject: "物理",
        PageIndex: 1,
        PageSize: 10,
      },
      // 年份数据配置
      YearData: [
        { name: 2016, value: "2016" },
        { name: 2017, value: "2017" },
        { name: 2018, value: "2018" },
        { name: 2019, value: "2019" },
        { name: 2020, value: "2020" },
      ],
      provinceObj: {}
    };
  },
  computed: {},
  mounted() {
    this.query.SchoolId = this.$route.query.id;
    this.initialize();
  },
  methods: {
    oldTextBtn() {
      this.showOldmes = !this.showOldmes;
      if (this.showOldmes) {
        this.query.SelectSubject = null;
        this.query.SubjectType = 1;
      } else {
        this.query.SelectSubject = "物理";
        this.query.SubjectType = null;
      }
      this.getMainData();
    },
    //翻页
    turnPage(currentPage) {
      this.query.PageIndex = currentPage;
      this.getMainData();
    },
    //选择科目, 招生方向
    selectParameters() {
      this.query.PageIndex = 1;
      this.getMainData();
    },
    //选择省份
    selectProvince() {
      this.query.PageIndex = 1;
      this.showOldmes = false;
      this.judgeNewExamStatus();
    },
    //初始化数据
    initialize() {
      //获取省份列表
      // this.$store.dispatch('user/getPowerProvinceList').then(provinceList => {
      //   this.provinceList = provinceList
      //   this.query.ProvinceId = provinceList[0].ID
      //   //新高考
      //   this.judgeNewExamStatus();
      // })
      //获取省份列表
      let temp = getStore('OrgProvince')
      if (temp) {
        this.provinceObj = JSON.parse(temp)
      }
      this.query.ProvinceId = areaData[this.provinceObj.ProvinceId]
      //新高考
      this.judgeNewExamStatus()
    },
    //获取列表数据
    getMainData() {
      API.School.SchoolEnrollment(this.query).then((res) => {
        this.mainData = res.data;
      });
    },
    //新高考状态判定
    judgeNewExamStatus() {
      this.$store.dispatch('tool/getSystemYear').then(year => {

        this.$store.dispatch("user/judgeNewExam", {
          id: this.provinceObj.ProvinceId,
          year: year,
        })
          .then((newExam) => {
            this.isNewExam = newExam.IsNewExam;
            if (newExam.IsNewExam) {
              this.$delete(this.query, "SubjectType");
              if (newExam.RuleList.length === 2) {
                this.newExamActive = true;
                this.query.SelectSubject = "物理";
              } else {
                this.newExamActive = false;
                this.query.SelectSubject = "";
              }
              this.getMainData();
            } else {
              this.$delete(this.query, "SelectSubject");
              this.query.SubjectType = 1;
              this.getMainData();
            }
          });
      })
    },
  },
};
</script>

<style scoped lang="less">
.main {
  padding: 3rem 0;

  .head {
    .head-top {
      margin-bottom: 2rem;
    }

    .head-bottom {
      margin-bottom: 2rem;
      display: flex;

      .screen-item {
        display: flex;
        align-items: center;
        margin-right: 3rem;

        .title {}
      }
    }
  }

  .content {
    .content-table {}
  }
}

.textBtn {
  display: inline-block;
  margin-left: 6rem;
  color: rgba(var(--themecolor),1);
  cursor: pointer;
}
</style>
<style lang="less">
.screen-item .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.screen-item .el-select .el-input__inner:focus,.screen-item .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>