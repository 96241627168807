<template>
  <div class="main">
<!--    <div class="right-content">
      <el-table
        class="table"
        :data="mainData"
        :header-cell-style="headStyle"
        :cell-style="cellStyle"
        border
      >
        <el-table-column label="专业名称" prop="SpecialtyName"></el-table-column>
        <el-table-column label="评估结果" prop="ResultLevel"></el-table-column>
      </el-table>
    </div>-->
    <div class="level" v-for="(item, index) in mainData" :key="index">
      <div class="name">{{ item.Level }}</div>
      <div class="box">
        <div class="item" v-for="(subItem, subIndex) in item.Items" :key="subItem">{{ subItem }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { LearnSubjectAssessment } from '@/api/college_details'
  import API from '@/api/config'

  export default {
    name: "Assess",
    data () {
      return {
        mainData: [],
        schoolID: this.$route.query.id
      }
    },
    computed: {
      headStyle () {
        return {
          'background-color': '#2372D2',
          'color': '#ffffff',
          'text-align': 'center'
        }
      },
      cellStyle () {
        return {
          'text-align': 'center'
        }
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      //initialize navigation list data
      initialize () {
        API.School.LearnSubjectAssessment({
          SchoolId: this.schoolID
        }).then(res => {
          this.mainData = res.data
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .main {
    margin-top: 30px;
    padding: 30px 0;
    .level {
      padding: 20px;
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 10px;

      display: flex;
      .name {
        width: 200px;
        padding: 10px 20px;
        flex-shrink: 0;
        color: rgba(var(--themecolor),1);
        font-weight: 700;
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        .item {
          padding: 10px 20px;
          color: var(--text-font-color);
          font-size: var(--text-font-size);
        }
      }
    }
  }
</style>
