<template>
  <div class="app-container">
    <div class="container">
      <div class="container-content">
        <div class="left">
          <div class="left-top">
            <div class="left-top-top">
              <div class="logo">
                <img class="logo-img" :src="schoolInfo.Logo" />
              </div>
              <div class="name">{{ schoolInfo.SchoolName }}</div>
            </div>
            <div class="left-top-bottom">
              <el-button class="button" size="small" type="primary" round @click="officialLink()">进入院校官网</el-button>
              <el-button class="button" @click="openReport(schoolInfo.ReportUrl)" type="primary" round
                size="small">就业质量报告预览
              </el-button>
            </div>
          </div>
          <!-- <div class="left-bottom">
            <div class="left-bottom-item" v-for="(item, index) in schoolInfo.TagStr" :key="index">{{ item }}</div>
          </div> -->
          <ul v-if="schoolInfo.TagStr && schoolInfo.TagStr[0]" class="content-left-bottom">
            <div class="tag-box" :class="openstatus==1?'hide':'show'">
              <li v-for="(item, index) in schoolInfo.TagStr" :key="index" class="tag-list">
                {{ item }}
              </li>
            </div>
          </ul>
          <div class="openbtn" v-if="openstatus==1" @click.prevent="openbtn(2)">展开 <i class="iconfont" style="display:inline-block;">&#xe600;</i></div>
          <div class="openbtn" v-else @click.prevent="openbtn(1)">收起 <i class="iconfont" style="transform: rotate(180deg);display:inline-block;">&#xe600;</i></div>
        </div>
        <div class="right">
          <div class="right-title">杰出校友</div>
          <div class="right-content" v-for="(item, index) in mainData" :key="index">
            <img class="right-content-img" :src="defaultPortrait" />
            <div class="right-content-synopsis">
              <div class="name">{{ item.Name }}</div>
              <ul class="info">
                <li>
                  <span>职业：</span>
                  <span>{{ item.Profession || '暂无数据' }}</span>
                </li>
                <li>
                  <span>就读院校：</span>
                  <span>北京大学</span>
                </li>
                <li>
                  <span>就读专业：</span>
                  <span>{{ item.SpecialtyName || '暂无数据' }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="no-data" v-if="mainData.length === 0">暂无数据...</div>
          <div class="contrast-box">
            <el-button class="add-contrast-button" @click="addContrast" :type="contrastStatus ? 'danger' : 'primary'"
              size="small" plain>{{ contrastStatus ? '取消对比' : '+ 对比' }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="university-detail-main">
      <el-tabs v-model="activate">
        <el-tab-pane label="学校概况" name="1">
          <college-situation></college-situation>
        </el-tab-pane>
        <el-tab-pane label="开设专业" name="2">
          <establish-specialty></establish-specialty>
        </el-tab-pane>
        <el-tab-pane label="学科评估" name="8">
          <assess></assess>
        </el-tab-pane>
        <el-tab-pane label="招生章程" name="5">
          <college-constitution></college-constitution>
        </el-tab-pane>
        <el-tab-pane label="选考要求" name="3">
          <member-limit v-if="!authorization"></member-limit>
          <select-exam v-else></select-exam>
        </el-tab-pane>
        <el-tab-pane label="招生计划" name="4">
          <member-limit v-if="!authorization"></member-limit>
          <enrollment-plan v-else></enrollment-plan>
        </el-tab-pane>
        <el-tab-pane label="院校分数线" name="6">
          <member-limit v-if="!authorization"></member-limit>
          <college-score-line v-else></college-score-line>
        </el-tab-pane>
        <el-tab-pane label="专业分数线" name="7">
          <member-limit v-if="!authorization"></member-limit>
          <specialty-score-line v-else></specialty-score-line>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import API from "@/api/config";

// import DetailIntroduction from '@/views/tool/university/local-components/DetailIntroduction'
import EstablishSpecialty from '@/views/tool/university/local-components/EstablishSpecialty'
import SelectExam from '@/views/tool/university/local-components/SelectExam'
import EnrollmentPlan from '@/views/tool/university/local-components/EnrollmentPlan'
import CollegeScoreLine from '@/views/tool/university/local-components/CollegeScoreLine'
import SpecialtyScoreLine from '@/views/tool/university/local-components/SpecialtyScoreLine'
import CollegeConstitution from '@/views/tool/university/local-components/CollegeConstitution'
import CollegeSituation from '@/views/tool/university/local-components/CollegeSituation'
import Assess from '@/views/tool/university/local-components/Assess'

import MemberLimit from "../../choose-wish/local-components/MemberLimit.vue";

export default {
  name: "University-Detail",
  components: {
    // DetailIntroduction,
    EstablishSpecialty,
    SelectExam,
    EnrollmentPlan,
    CollegeScoreLine,
    SpecialtyScoreLine,
    CollegeConstitution,
    CollegeSituation,
    Assess,

    MemberLimit
  },
  data() {
    return {
      mainData: [],
      schoolInfo: {},
      contrastStatus: false,
      activate: '1',
      defaultLogo: require("@/assets/tool/search-university/default-logo.jpg"),
      defaultPortrait: require('@/assets/tool/search-university/default-portrait.jpg'),
      localContrastList: '',

      authorization: false,
      openstatus:1
    }
  },
  computed: {
    contrastList() {
      let temp = this.$store.getters.contrastList
      let flag = temp.some(item => {
        if (item.ID === this.$route.query.id) {
          return true
        }
      })
      this.contrastStatus = flag
      return temp
    }
  },
  watch: {
    contrastList() { }
  },
  mounted() {
    this.initialize()
    this.receiveSchoolInfo()
    this.localContrastList = this.contrastList

    // 权限
    this.$store.dispatch('user/allowMember', ['any']).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    openbtn(t){
      this.openstatus=t;
    },
    //添加到对比列表
    addContrast() {
      let schoolID = this.$route.query.id
      let temp = this.contrastList.find(item => {
        if (item.ID === schoolID) {
          return true
        }
      })
      if (temp) {
        this.localContrastList.$remove(temp, 'ID')
        this.contrastStatus = false
      } else {
        if (this.contrastList.length >= 20) {
          this.$messageTips('info', '对比院校最多添加20个！')
          return
        }
        this.contrastStatus = true
        this.localContrastList.push({
          SchoolName: this.schoolInfo.SchoolName,
          ID: schoolID,
          check: true,
        })
      }
      this.$store.dispatch('user/setContrastList', this.localContrastList).then(_ => {
        this.$setStorage('contrastList', this.localContrastList)
      })
    },
    //official website link
    officialLink() {
      if (this.schoolInfo.WebSite) {
        let temp = this.schoolInfo.WebSite.substring(0, 4)
        if (temp === 'http') {
          window.open(this.schoolInfo.WebSite)
        } else {
          window.open('//' + this.schoolInfo.WebSite)
        }
      } else {
        this.$messageTips('warning', '暂无官网地址！')
      }
    },
    //打开质量报告
    openReport(url) {
      if (url) {
        window.open(url)
      } else {
        this.$messageTips('warning', '暂无相关报告！')
      }
    },
    //接受学校信息
    receiveSchoolInfo() {
      this.$globalEventBus.$on('schoolInfo', data => {
        this.schoolInfo = data || {}
        this.schoolInfo.TagStr = this.schoolInfo.TagStr.split(',')
      })
    },
    //初始化数据
    initialize() {
      API.School.SchoolAlumnus({
        SchoolId: this.$route.query.id
      }).then(res => {
        this.mainData = res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.app-container {
  position: relative;
}

.container {
  // width: 100%;
  // height: 400px;
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  background-color: rgba(var(--themecolor),0.75);

  &-content {
    width: 1250px;
    // margin: 2rem auto 1rem;
    padding: 2rem;
    color: #fff;
    display: flex;
    justify-content: space-between;

    .left {
      width: 41rem;

      &-top {
        padding: 2rem 3rem;
        background-color: #fff;

        &-top {
          padding-bottom: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid var(--color-grey);

          .logo {
            &-img {
              width: 3rem;
              height: 3rem;
            }
          }

          .name {
            margin-left: 1rem;
            font-size: var(--title-font-size);
            color: var(--text-font-color);
          }
        }

        &-bottom {
          display: flex;
          justify-content: space-between;
          padding-top: 1rem;
          text-align: right;

          .button {
            width: 30%;
          }
        }
      }

      .content-left-bottom {
        width: 100%;
        justify-content: space-between;
        padding: 1rem 0 5px;
        margin-top: 5px;
        border-radius: 3px;

        &::-webkit-scrollbar {
          height: 5px;
        }

        &::-webkit-scrollbar-thumb {
          color: rgb(var(--themecolor));
          background-color: rgb(177, 197, 226);
          border-radius: 3px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #fafafa;
        }

        .tag-box.show{
         height: auto;

        }
        .tag-box.hide{
         height: 30px;
         overflow: hidden;

        }
        .tag-list {
          display: inline-block;
          padding: 0 0.5rem;
          color: #e4e1e1;
          border: 1px solid #ddd;
          border-radius: 20px;
          margin: 4px 5px;
          font-size: 13px;
          .xun-zhang {
            width: 24px;
          }
        }
      }
      .openbtn{
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 13px;
        cursor: pointer;
      }
      .openbtn:hover{
        opacity: 0.8;
      }
      // &-bottom {
      //   margin-top: 1rem;
      //   display: flex;
      //   flex-wrap: wrap;
      //   justify-content: space-between;

      //   &-item {
      //     width: 10.5rem;
      //     text-align: center;
      //     margin-bottom: 1rem;
      //     padding: .6rem;
      //     background: url("./../../../assets/tool/search-university/tag-background.png") no-repeat center/100% 100%;

      //   }
      // }
    }

    .right {
      width: 30rem;
      position: relative;

      &-title {}

      &-content {
        margin-top: .5rem;

        &-img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }

        &-synopsis {
          .name {}

          .info {
            font-size: var(--title-font-size);
          }
        }
      }
    }
  }
}

.university-detail-main {
  // margin-top: 23rem;

  ::v-deep .el-tabs__header {
    margin-bottom: 0;
  }

  ::v-deep .el-tabs__nav {
    float: none;
    width: 58%;
    margin: 0 auto;
    padding: 1rem 0;
  }

  ::v-deep .el-tabs__content {
    /*background-color: red;*/
  }
}

.no-data {
  padding: 2rem 0;
}

.contrast-box {
  position: absolute;
  top: 0;
  right: 150px;

  .add-contrast-button {
    width: 120px;
  }
}</style>
<style lang="less">
.left-top-bottom .el-button--primary,.left-top-bottom .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.left-top-bottom .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.contrast-box .el-button--danger.is-plain{
  color: #666;
}
.contrast-box .el-button--danger.is-plain:hover{
  background-color: #fff;
  color: #666;
}
.contrast-box .el-button--primary.is-plain{
  color: #666;
  border-color: #fff;
}
.contrast-box .el-button--danger.is-plain:focus{
  background-color: #fff;
  border-color: #fff;
}
.contrast-box .el-button--primary.is-plain:hover{
  background-color: #fff;
  border-color: #fff;
  color: #666;
}
.contrast-box .el-button--primary.is-plain:focus{
  background: #fff;
}
.university-detail-main .el-tabs__active-bar{
  background-color: rgba(var(--themecolor),0.75);
}
.university-detail-main .el-tabs__item.is-active{
  color: rgba(var(--themecolor),1);
}
.university-detail-main .el-tabs__item:hover{
  color: rgba(var(--themecolor),0.75);
}
.university-detail-main .el-tabs__item:focus.is-active.is-focus:not(:active){
  box-shadow: none;
}
</style>