<template>
  <div class="main">
    <div class="content-item">
      <div class="title">
        <div class="name">历史沿革</div>
      </div>
      <div class="box">
        <div class="box-item" v-for="(item, index) in historyData" :key="index">
          <div class="border-left"></div>
          <div class="name">
            <div class="icon"></div>
            {{ item.DatePoint }}
          </div>
          <div class="text">{{ item.Description }}</div>
        </div>
      </div>
      <div class="more-button">
        <el-button v-if="historyData.length !== 0" @click="showMoreHistory" type="primary" size="small">{{
          historyButtonText }}更多
        </el-button>
      </div>
    </div>
    <div class="content-item">
      <div class="title">
        <div class="name">学校简介</div>
      </div>
      <div class="box">
        <div class="info-describe" :class="{'info-describe-more':activateMoreInfo}" v-html="schoolInfo.SchoolDesc"></div>
        <div></div>
      </div>
      <div class="more-button">
        <el-button @click="showMoreInfo" type="primary" size="small">{{ infoButtonText }}更多</el-button>
      </div>
    </div>
    <div v-if="proportionShow" class="content-item">
      <div class="title">
        <div class="name">男女比例和深造情况</div>
      </div>
      <div class="percent-box">
        <div class="percent-item only-gender-proportion">
          <div>
            <div class="name">
              男生比例：{{schoolInfo.PercentOfMale}}%
            </div>
            <div class="name">
              女生比例：{{schoolInfo.PercentOfFemale}}%
            </div>
          </div>
          <el-progress :percentage="schoolInfo.PercentOfMale||0" type="circle" :width="100"></el-progress>
          <div class="proportion">
            <div>男：{{schoolInfo.PercentOfMale}}%</div>
            <div>女：{{schoolInfo.PercentOfFemale}}%</div>
          </div>
        </div>
        <div v-if="schoolInfo.PercentOfPostgraduate" class="percent-item">
          <div class="name">
            读研比例
          </div>
          <el-progress :percentage="schoolInfo.PercentOfPostgraduate||0" type="circle" :width="100"></el-progress>
        </div>
        <div v-if="schoolInfo.PercentOfGoAbroad" class="percent-item">
          <div class="name">
            出国留学比例
          </div>
          <el-progress :percentage="schoolInfo.PercentOfGoAbroad||0" type="circle" :width="100"></el-progress>
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="title">
        <div class="name">科研实力</div>
      </div>
      <div class="box">
        <div class="box-head">
          <div class="box-head-item" :class="{'activate-selected': activateLaboratory}" @click="toggleLaboratory(true)">
            实验室与研究机构
          </div>
          <div class="box-head-item" :class="{'activate-selected': !activateLaboratory}"
               @click="toggleLaboratory(false)">师资力量
          </div>
        </div>
        <div class="box-main">
          <div v-show="activateLaboratory" class="laboratory">
            <div class="laboratory-item" v-for="(item, index) in mainData.laboratoryList" :key="index">
              <span class="name">{{ item.CategoryName }}：</span>
              <span>{{ item.LabNames }}</span>
            </div>
          </div>
          <div v-show="!activateLaboratory" class="capital">
            {{ schoolInfo.TeachersDescription }}
          </div>
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="title">
        <div class="name">院校排名</div>
      </div>
      <div class="list">
        <div class="list-item" v-for="(item, index) in rankList" :key="index">
          <div class="name">{{ item.RankingTypeName }}</div>
          <div class="text">
            {{ item.RankingOfInternal }}
            <span v-if="item.RankingOfExternal">/{{ item.RankingOfExternal }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-item">
      <div class="title">
        <div class="name">其他指标</div>
      </div>
      <div class="box">
        <div class="top">
          <div id="quota_pie" class="left"></div>
          <div class="right">
            <div class="title">
              综合评级
            </div>
            <div class="right-list">
              <div class="right-list-item" v-for="(item, index) in mainData.quota" :key="index">
                <div class="name">{{ item.IndicatorName }}</div>
                <div class="value">{{ item.Score }}</div>
              </div>
              <div v-if="mainData.quota.length !== 0" class="right-list-item total-score">
                <div class="name">总分</div>
                <div class="value">{{ totalScore }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom"></div>
      </div>
    </div>
    <div class="content-item">
      <div class="title">
        <div class="name">校园风景</div>
      </div>
      <div class="box">
        <div class="scenery">
          <div class="scenery-item" v-for="(item, index) in schoolInfo.ImageList" :key="index">
            <img class="image" :src="item.ImageUrl" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content-item">
      <div class="title">
        <div class="name">地图</div>
      </div>
      <div v-show="schoolInfo.Location" id="map" class="map"></div>
      <div v-show="!schoolInfo.Location" class="map-tips">暂无地图信息</div>
    </div> -->
    <college-contrast></college-contrast>
  </div>
</template>

<script>
  // import AMap from 'AMap'
  // import {SchoolHistory, SchoolRanking, SchoolIndicate, LaboratoryGroupByCategory} from '@/api/college_details'
  // import {SchoolInfo} from "@/api/college";
  import API from '@/api/config'

  import CollegeContrast from './CollegeContrast'

  export default {
    components:{
      CollegeContrast,
    },
    name: "CollegeSituation",
    data () {
      return {
        schoolID: this.$route.query.id,
        historyButtonText: '展开',
        infoButtonText: '展开',
        activateMoreInfo: false,
        activateLaboratory: true,
        mainData: {
          history: [],
          quota: [],
          laboratoryList: []
        },
        historyData: [],
        schoolInfo: {},
        rankList: [],
      }
    },
    computed:{
      totalScore(){
        let score = 0
        this.mainData.quota.forEach(item => {
          score += item.Score
        })
        return score.toFixed(2)
      },
      proportionShow(){
        let flag
        if(
          !this.schoolInfo.PercentOfGoAbroad&&
          !this.schoolInfo.PercentOfPostgraduate&&
          !this.schoolInfo.PercentOfFemale&&
          !this.schoolInfo.PercentOfMale
        ){
          flag = false
        }else{
          flag = true
        }
        return flag
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      //toggle laboratory tab
      toggleLaboratory (flag) {
        this.activateLaboratory = flag
      },
      //show more school info
      showMoreInfo () {
        const handleOne = () => {
          this.infoButtonText = '展开'
          this.activateMoreInfo = false
        }
        const handleTwo = () => {
          this.infoButtonText = '收起'
          this.activateMoreInfo = true
        }
        this.infoButtonText === '展开' ? handleTwo() : handleOne()
      },
      //show more history event
      showMoreHistory () {
        const handleOne = () => {
          this.historyData = this.mainData.history
          this.historyButtonText = '收起'
        }
        const handleTwo = () => {
          this.historyData = this.mainData.history.slice(0, 3)
          this.historyButtonText = '展开'
        }
        this.historyButtonText === '展开' ? handleOne() : handleTwo()
      },
      initialize () {
        this.historyInfo()

        this.info()

        this.ranking()

        this.quotaPie()

        this.laboratoryData()
      },
      // history list
      historyInfo () {
        API.School.SchoolHistory({
          SchoolId: this.schoolID,
        }).then(res => {
          //排序
          const sortFn = (v1, v2) => {
            return v1.Sort - v2.Sort
          }
          this.mainData.history = res.data.sort(sortFn)
          this.historyData = this.mainData.history.slice(0, 3)
        })
      },
      //school info
      info () {
        let loading = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0,0,0,.3)'
        })
        API.School.SchoolInfo({
          Id: this.schoolID
        }).then(res => {
          this.schoolInfo = res.data
          // this.handleMap(res.data.Location)
          this.$globalEventBus.$emit('schoolInfo', res.data)
          loading.close()
        })
      },
      //map initialize
      // handleMap (coordinate) {
      //   let temp = coordinate.split(',')
      //   let myCoordinate = [temp[0] * 1, temp[1] * 1]
      //   if (AMap) {
      //     if (coordinate[0]) {
      //       let map = new AMap.Map('map', {
      //         zoom: 13,
      //         center: myCoordinate,
      //         resizeEnable: true
      //       })
      //       map.plugin(
      //         ["AMap.ToolBar", "AMap.ControlBar", "AMap.Geolocation", "AMap.MapType"],
      //         function () {
      //           // 在图面添加控件, 工具条控件只有缩放功能
      //           const toolBar = new AMap.ToolBar();
      //           const controlBar = new AMap.ControlBar();
      //           const MapType = new AMap.MapType();

      //           map.addControl(toolBar);
      //           map.addControl(controlBar);
      //           map.addControl(MapType);
      //         }
      //       );
      //       new AMap.Marker({
      //         position: myCoordinate,
      //         draggable: false,
      //         map: map
      //       });
      //     }
      //   }
      // },
      ranking () {
        API.School.SchoolRanking({
          SchoolId: this.schoolID,
          PageIndex: 1,
          PageSize: 999
        }).then(res => {
          this.rankList = res.data.Data
        })
      },
      quotaPie () {
        API.School.SchoolIndicate({
          SchoolId: this.schoolID,
        }).then(res => {
          this.mainData.quota = res.data

          let seriesDataArray = res.data.map(item => {
            return {
              value: item.Score,
              name: item.IndicatorName,
              tips: item.IndicatorDescription
            }
          })
          //pie picture
          const myChart = this.$echarts.init(document.getElementById('quota_pie'))
          let option = this.$echartsPieOption('pie', '', '', seriesDataArray)
          // 使用刚指定的配置项和数据显示图表。
          myChart.setOption(option);
        })
      },
      laboratoryData () {
        API.School.LaboratoryGroupByCategory({
          SchoolId: this.schoolID
        }).then(res => {
          this.mainData.laboratoryList = res.data
        })
      }
    }
  }
</script>

<style scoped lang="less">

  .main {
    padding: 20px 0;
    .content-item {
      .title {
        .name {
          padding: 40px;
          text-align: center;
          font-weight: 700;
          font-size: var(--text-font-size);
        }
      }
      .box {
        &-item {
          position: relative;
          .border-left {
            width: 1px;
            background-color: rgba(var(--themecolor),0.75);

            position: absolute;
            top: 0;
            bottom: 0;
            left: 6.5px;
          }
          &:last-child {
            .border-left {
              display: none;
            }
          }
          .name {
            padding-bottom: 20px;
            font-size: var(--text-font-size);
            color: var(--text-font-color);
            display: flex;
            align-items: center;
            .icon {
              width: 15px;
              height: 15px;
              background-color: rgba(var(--themecolor),0.75);
              border-radius: 50%;
              margin-right: 10px;
            }
          }
          .text {
            padding: 0 0 20px 45px;
          }
        }
        .info-describe {
          height: 10rem;
          line-height: 1.5;
          overflow: hidden;
          color: var(--text-font-color);
          letter-spacing: 2px;
          white-space: pre-wrap;
        }
        .info-describe-more {
          height: auto;
        }
        .top {
          display: flex;
          .left {
            width: 50%;
            height: 400px;
          }
          .right {
            width: 50%;
            .title {
              padding: 10px;
              text-align: center;
              background-color: var(--color-grey);
            }
            &-list {
              border: 1px solid var(--color-grey);
              &-item {
                display: flex;
                align-items: center;
                text-align: center;
                border-bottom: 1px dashed var(--color-grey);
                color: var(--text-font-color);
                &:last-child {
                  border-bottom: none;
                }
                .name {
                  padding: 15px;
                  width: 50%;
                  border-right: 1px dashed var(--color-grey);
                }
                .value {
                  padding: 15px;
                  width: 50%;
                }
              }
            }
          }
        }
        &-head {
          display: flex;
          align-items: center;
          border-bottom: 5px solid rgba(var(--themecolor),0.75);
          &-item {
            width: 12rem;
            padding: 1rem;
            text-align: center;
            background-color: var(--color-grey);
            cursor: pointer;
            font-size: var(--text-font-size);
            font-weight: 700;
            color: var(--text-font-color);
            transition: background-color .2s linear;
          }
          .activate-selected {
            background-color: rgba(var(--themecolor),0.75);
            color: #ffffff;
            border-bottom: 1px solid rgba(var(--themecolor),0.75);
          }
        }
        &-main {
          margin-top: 5px;
          padding: 20px;
          border: 1px solid var(--color-grey);
          color: var(--text-font-color);
          .laboratory {
            &-item {
              margin-bottom: 10px;
              .name {
                font-weight: 700;
                color: var(--text-font-color);
              }
            }
          }
          .capital {
            line-height: 1.5;
          }
        }
        .scenery {
          display: flex;
          flex-wrap: wrap;
          &-item {
            width: calc(100% / 3);
            text-align: center;
            .image {
              width: 330px;
              height: 200px;
              vertical-align: top;
            }
          }
        }
      }
      .more-button {
        margin: 1rem 0;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: 160px;
          margin: 0 2.5rem 2rem 0;
          border: 1px solid var(--color-grey);
          text-align: center;
          .name {
            padding: 10px 20px;
            font-weight: 700;
            background-color: rgba(0, 0, 0, .1);
          }
          .text {
            padding: 20px 0;
          }
        }
      }
      .percent-box {
        display: flex;
        flex-wrap: wrap;

        box-sizing: border-box;
        .percent-item {
          width: 340px;
          margin-right: 20px;
          padding: 10px 20px;
          border: 1px solid var(--color-grey);
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

        }
        .only-gender-proportion {
          position: relative;
          .proportion{
            font-size: 14px;
            color: var(--text-font-color);
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
          }
          ::v-deep .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
            display: none;
          }
        }
      }
      .map {
        width: 1000px;
        height: 500px;
        margin: 0 auto;
        border: 1px solid var(--color-grey);
      }
      .map-tips {
        width: 1000px;
        height: 500px;
        line-height: 500px;
        margin: 0 auto;
        border: 1px solid var(--color-grey);
        font-size: 50px;
        color: var(--text-font-color);
        text-align: center;
      }
    }
  }
</style>
<style lang="less">
.more-button .el-button--primary{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.more-button .el-button--primary:hover{
  background-color: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
</style>