<template>
  <div class="main">
    <div class="head"></div>
    <div class="content">
      <div class="content-top">
        <div class="content-item">
          <h4 class="title">优势专业</h4>
          <ul class="box">
            <li class="box-item"
                v-for="(superioritySpecialtyItem, index) in mainData.SuperioritySpecialtyList"
                :key="index"
                @click="toSpecialtyDetails(superioritySpecialtyItem)"
            >
              {{superioritySpecialtyItem.SpecialtyName}}
            </li>
          </ul>
        </div>
        <div class="content-item">
          <h4 class="title">特色专业</h4>
          <ul class="box">
            <li class="box-item"
                v-for="(specialSpecialtyItem, index) in mainData.SpecialSpecialtyList"
                :key="index"
                @click="toSpecialtyDetails(specialSpecialtyItem)"
            >
              {{specialSpecialtyItem.SpecialtyName}}
            </li>
          </ul>
        </div>
      </div>
      <div class="content-bottom">
        <div class="content-item" v-for="(item, index) in mainData.Specialty" :key="index">
          <h4 class="title">{{item.Category}}</h4>
          <ul class="box">
            <li class="box-item"
                v-for="(specialtyItem, specialtyIndex) in item.SpecialtyList"
                :key="specialtyIndex"
                @click="toSpecialtyDetails(specialtyItem)"
            >
              {{specialtyItem.SpecialtyName}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import {SchoolSpecialty} from '@/api/search_university'
  import API from '@/api/config'

  export default {
    data() {
      return {
        mainData: {},
        Id: this.$route.query.id
      }
    },
    mounted() {
      this.initialize()
    },
    methods: {
      //进出对应专业详情
      toSpecialtyDetails(query){
        let routerUrl
        if (query.SpecialtyCode.length === 4) {
          routerUrl = this.$router.resolve({
            path: "/specialty-list",
            query: {
              SpecialtyCode: query.SpecialtyCode,
            }
          });
        } else if (query.SpecialtyCode.length === 6) {
          routerUrl = this.$router.resolve({
            path: "/specialty-detail",
            query: {SpecialtyCode: query.SpecialtyCode}
          });
        }
        window.open(routerUrl.href)
      },
      //初始化数据
      initialize() {
        API.School.SchoolSpecialty({
          Id: this.Id
        }).then(res => {
          this.mainData = res.data
        })
      }
    },
  }
</script>

<style scoped lang="less">
  .main{
    margin-top: 2rem;
    .head{}
    .content{
      .content-bottom,.content-top{
        padding: 2rem;
        background-color: #fff;
        border-radius: .5rem;
        .content-item{
          padding: 1rem 0;
          border-bottom: 1px solid var(--color-grey);
          display: flex;
          align-items: center;
          .title{
            margin: 0 2.5rem 0 0;
            flex: 0 0 10rem;
            color: var(--text-font-color);
          }
          .box{
            display: flex;
            flex-wrap: wrap;
            .box-item{
              padding: .5rem 1rem;
              font-size: var(--text-font-size);
              color: var(--text-font-color);
              cursor: pointer;
              transition: color .2s linear;
              &:hover{
                color: rgba(var(--themecolor),1);
              }
            }
          }
        }
      }
      .content-top{
        .content-item:last-child{
          border-bottom: none;
        }
      }
      .content-bottom{
        margin-top: 2rem;
      }
    }
  }
</style>
