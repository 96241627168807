<template>
  <div class="main">
    <div class="head">
      <!-- <div v-if="directionData.length !== 0" class="head-top">
        <el-radio-group v-model="query.EnrollmentDirectionId" @change="selectParameters">
          <el-radio-button
            v-for="(item,index) in directionData"
            :key="index"
            :label="item.ID"
          >{{ item.DirectionName }}
          </el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="head-bottom">
        <div class="province screen-item">
          <div class="title">招生省份：</div>
          {{ provinceObj.ProvinceName }}
          <!-- <el-select v-model="query.ProvinceId" placeholder="请选择省份" @change="selectProvinceAndYear">
            <el-option
              v-for="(item, index) in provinceList"
              :key="index"
              :label="item.ProvinceName"
              :value="item.ID"
            />
          </el-select> -->
        </div>
        <div class="year screen-item">
          <div class="title">年份：</div>
          <el-select v-model="query.Year" placeholder="请选择年份" @change="selectProvinceAndYear">
            <el-option
              v-for="(item,index) in YearData"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
        <div>
          <template v-if="!isNewExam">
            <div class="subject screen-item">
              <div class="title">科类：</div>
              <el-radio-group v-model="query.SubjectType" @change="selectParameters">
                <el-radio-button :label="1">文科</el-radio-button>
                <el-radio-button :label="2">理科</el-radio-button>
              </el-radio-group>
            </div>
          </template>
          <template v-else-if="isNewExam&&newExamActive">
            <div class="new-exam screen-item">
              <div class="title">科目：</div>
              <el-radio-group v-model="query.SelectSubject" @change="selectParameters">
                <el-radio-button label="物理">物理</el-radio-button>
                <el-radio-button label="历史">历史</el-radio-button>
              </el-radio-group>
            </div>
          </template>
        </div>
        <div v-if="isNewExam && newExamActive && (specialtyGroupList.length > 0)" class="year screen-item">
          <div class="title">专业组：</div>
          <el-select
            v-model="query.GroupCode"
            placeholder="请选择专业组"
            @change="selectGroupCode"
          >
            <el-option
              v-for="(item, index) in specialtyGroupList"
              :key="index"
              :label="item.GroupName"
              :value="item.GroupCode"
            />
          </el-select>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-table">
        <el-table
          :data="mainData.Data"
          border
          empty-text="暂无数据..."
          :header-cell-style="{background: 'rgba(var(--themecolor),0.2)', color: '#555'}"
          style="width: 100%"
        >
         <el-table-column
            align="center"
            prop="SchoolName"
            label="院校名称"
          />
          <el-table-column align="center" label="专业名称">
            <template slot-scope="props">
              <div
                class="hover-color"
                @click="toSpecialityDetails(props.row)"
              >
                {{ props.row.EnrollmentSpecialty }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="ScoreHigh" width="160" label="最高分"/> -->
          <el-table-column align="center" prop="ScoreLow" label="最低分" width="160"/>
          <el-table-column align="center" prop="ScoreAvg" width="160" label="平均分"/>
          <el-table-column align="center" prop="RankLow" label="最低位次" width="160"/>
          <el-table-column align="center" prop="ScoreDiff" label="线差" width="140"/>
          <el-table-column align="center" prop="Persons" width="100" label="录取人数"/>
          <el-table-column align="center" prop="BatchName" width="120" label="批次"/>
        </el-table>
      </div>
      <custom-pagination
        v-show="mainData.Total > 0"
        :pageIndex="query.PageIndex"
        :pageSize="query.PageSize"
        :total="mainData.Total"
        @handleCurrentChange="turnPage"
      ></custom-pagination>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { getStore } from '@/utils/utils';
import {areaData} from '@/utils/data';
  // import {
  //   SchoolSpecialtyScoreLine,
  //   SchoolEnrollmentDirection,
  // } from "@/api/search_university";
  export default {
    name: "SpecialityOnline",
    data() {
      const nowTime = new Date().getFullYear()
      return {
        schoolID:'',
        mainData:[],
        directionData:[],
        provinceList:[],
        //新高考状态参数
        isNewExam: false,
        newExamActive: false,
        //查询参数
        query:{
          SchoolId:'',
          // EnrollmentDirectionId:'',
          ProvinceId:'',
          Year:nowTime - 2,
          SubjectType:1,
          SelectSubject:'物理',
          PageIndex:1,
          PageSize: 10,
        },
        // 年份数据配置
        YearData: [
         {name: nowTime, value: nowTime},
          {name: nowTime - 1, value: nowTime - 1},
          {name: nowTime - 2, value: nowTime - 2},
          {name: nowTime - 3, value: nowTime - 3},
          {name: nowTime - 4, value: nowTime - 4}
        ],
        provinceObj:{},

        specialtyGroupList: []
      }
    },
    computed:{},
    mounted() {
      this.query.SchoolId = this.$route.query.id
      this.initialize()
    },
    methods: {

      selectGroupCode() {
        this.query.PageIndex = 1;
        this.getMainData();
      },
      // 获取专业组
      async getSpecialtyGroup() {
        let params = {
          SchoolId: this.query.SchoolId,
          ProvinceId: this.query.ProvinceId,
          Year: Number(this.query.Year),
          SelectSubject: this.query.SelectSubject || ''
        }
        let res = await API.School.SchoolSpecialtyScoreGroup(params)

        res.data = res.data || []

        if (res.data.length>0) {
          this.query.GroupCode = res.data[0].GroupCode
        } else {
          this.query.GroupCode = ''
        }
        this.specialtyGroupList = res.data
        
        return res
      },
      //查看对应专业
      toSpecialityDetails(query){
        let routerUrl
        if (query.SpecialtyCode.length === 4) {
            routerUrl = this.$router.resolve({
            path: "/specialty-list",
            query: {
              CategoryCode: query.SpecialtyCode,
              CategoryName: query.EnrollmentSpecialty
            }
          });


        } else if (query.SpecialtyCode.length === 6) {
          routerUrl = this.$router.resolve({
            path: "/specialty-detail",
            query: {SpecialtyCode: query.SpecialtyCode}
          });
        }


        window.open(routerUrl.href)
      },
      //翻页
      turnPage(currentPage){
        this.query.PageIndex = currentPage
        this.getMainData()
      },
      //选择年份 科目 招生方向
      selectParameters(){
        this.query.PageIndex = 1
        if (this.isNewExam) {
          this.getSpecialtyGroup().then(() => {
            this.getMainData();

          })
        } else {
          this.getMainData();

        }
      },
      //选择省份 年份
      selectProvinceAndYear(){
        this.query.PageIndex = 1
        this.judgeNewExamStatus()
      },
      //初始化数据
      initialize(){
        // //获取用户信息
        // this.query.ProvinceId = this.ProvinceId
        // this.query.SubjectType = this.SubjectType
        // //获取省份列表
        // this.$store.dispatch('user/getPowerProvinceList').then(provinceList => {
        //   this.provinceList = provinceList;
        //   this.query.ProvinceId = provinceList[0].ID
        //   //新高考
        //   this.judgeNewExamStatus()
        // })

        //获取省份列表
        let temp = getStore('OrgProvince')
        if (temp) {
          this.provinceObj = JSON.parse(temp)
        }
        this.query.ProvinceId = areaData[this.provinceObj.ProvinceId]
        //新高考
        this.judgeNewExamStatus()
      },
      //获取列表数据
      getMainData(){
        API.School.SchoolSpecialtyScoreLine(this.query).then(res=>{
          this.mainData = res.data
        })
      },
      //新高考状态判定
      judgeNewExamStatus(){
        this.$store.dispatch('user/judgeNewExam',{id: this.provinceObj.ProvinceId, year: this.query.Year}).then(newExam=>{
          this.isNewExam = newExam.IsNewExam
          if(newExam.IsNewExam){
            this.$delete(this.query, 'SubjectType')
            if(newExam.RuleList.length>1){
              this.newExamActive = true
              this.query.SelectSubject = '物理'

              this.getSpecialtyGroup().then(() => {
                this.getMainData();
              })

            }else{
              this.newExamActive = false
              this.query.SelectSubject = ''

              this.$delete(this.query, "GroupCode");
              this.getMainData();
            }
            // this.getMainData()
          }else{
            this.$delete(this.query, 'SelectSubject')
            this.$delete(this.query, "GroupCode");
            
            this.query.SubjectType = 1
            this.getMainData()
          }
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .main {
    padding: 3rem 0;
    .head{
      .head-top{
        margin-bottom: 2rem;
      }
      .head-bottom{
        margin-bottom: 2rem;
        display: flex;
        .screen-item{
          display: flex;
          align-items: center;
          margin-right: 3rem;
          .title{}
        }
      }
    }
    .content{
      .content-table{
        .hover-color{
          transition: all .2s linear;
          cursor: pointer;
          &:hover{
            color: rgba(var(--themecolor),1);
          }
        }
      }
    }
  }
</style>
<style lang="less">
.screen-item .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.screen-item .el-select .el-input__inner:focus,.screen-item .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>